<template v-for="source in this.sources" >
  <b-tab  :title="source.key" :key="source.key">
    <AssetTab :collection="collectionID" :source="sourceID" :base_uri="source.base_uri" :assets="source.assets" :bands="bands" :has-bands="hasBands" />
  </b-tab>
</template>

<script>
import AssetTab from "./AssetTab.vue";

export default {
  components: {
    AssetTab,
  },
  name: "ItemSources",
  props: ["sources", "bands","hasBands" ]
};

</script>