<template>
  <div>
    <div>
      <NavBar></NavBar>
      <b-breadcrumb v-if="!errored" :items="breadcrumbs" />
      <b-alert v-if="errored" variant="danger" show>{{ _entity.message }}</b-alert>
      <b-container v-else>
        <b-row>
          <b-col md="8" class="pr-0">
            <h1 class="scroll">{{ title }}</h1>
            <b-spinner v-if="!pageLoaded" label="Loading..."></b-spinner>
            <div v-if="pageLoaded">
              <ul
                role="tablist"
                class="nav nav-tabs"
                v-if="sourcesDropdown && sourcesDropdown.length > 0"
                ref="tablist"
              >
                <li
                  role="presentation"
                  class="nav-item align-bottom"
                  :class="tabIndex > 0 ? 'active' : ''"
                  v-if="sourcesDropdown.length > tabIndex"
                >
                  <b-dropdown :text="sourceID ? sourceID : 'select source'" variant="link">
                    <b-dropdown-item
                      v-for="(source, index) in this.sourcesDropdown"
                      @click="selectTab(source)"
                      :key="index"
                      :class="source.hidden ? 'd-none' : ''"
                    >
                      {{ source }}
                    </b-dropdown-item>
                  </b-dropdown>
                </li>
              </ul>
              <div v-else>
                <p>There is no available data for this item</p>
              </div>
              <div v-if="preview"  id="thumbnail" >
                <img align="center" @error="errorImg" :src="preview" />
              </div>
              <div>
                <AssetTab :assets="assets" :base_uri="base_uri" :collection="collectionID" :source="sourceID" :bands="bands" :has-bands="false"  :urlLink="preview" :item_id="title"  />
              </div>
            </div>
          </b-col>

          <b-col md="4">
            <b-card bg-variant="light">
              <Map v-model="geometry" :tools="false"></Map>
              <MetadataSidebar
                :properties="properties"
                :keywords="keywords"
                :collection="collection"
                :collection-link="collectionLink"
                :providers="providers"
                :owned_by="owned_by"
                :labels="labels"
                :shared_with="shared_with"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import Leaflet from 'leaflet'
import { mapActions, mapGetters } from 'vuex'
import wicket from 'wicket'

import common from './common'
import Migrate from '@radiantearth/stac-migrate'

// ToDo 3.0: Import tabs lazily
import LinkTab from './LinkTab.vue'
import AssetTab from './AssetTab.vue'
import ItemSources from './ItemSources.vue'
import NavBar from './NavBar.vue'
import Map from './Map.vue'
import { getCatalogUrl } from '../util'

const FEATURES_TYPES = ['application/geo+json']

export default {
  ...common,
  name: 'ItemDetail',
  async beforeRouteUpdate(to, from, next) {
    let url = `${getCatalogUrl(to)}/collections/${to.params.collectionID}/items/${to.params.itemID}`
    if (to.params.sourceID) {
      url = `${getCatalogUrl(to)}/collections/${to.params.collectionID}/@/${to.params.sourceID}/items/${
            to.params.itemID
          }`
    }

    await this.$store.dispatch('load', url)

    return next()
  },
  components: {
    LinkTab,
    AssetTab,
    ItemSources,
    NavBar,
    Map,
    MetadataSidebar: () => import(/* webpackChunkName: "metadata-sidebar" */ './MetadataSidebar.vue'),
    MultiSelect: () => import(/* webpackChunkName: "multiselect" */ 'vue-multiselect')
  },
  props: {
    ancestors: {
      type: Array,
      required: true
    },
    center: {
      type: Array,
      default: null
    },
    path: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    collectionID: {
      type: String,
      required: true
    },
    itemID: {
      type: String,
      required: true
    },
    sourceID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      map: null,
      tabIndex: 0,
      tabsChanged: false,
      preview: null,
      sources: [],
      pageLoaded: true,
      geometry: null,
    }
  },
  computed: {
    ...common.computed,
    ...mapGetters(['getEntity']),
    base_uri() {
      return this.item?.assets?.base_uri || ""
    },
    assets() {
      /**  Build a list assets at every available sources for an item
       * [{
       *     ...value : asset content
       *     label: asset title
       *     href: link to the asset
       *     roleNames: asset role(s),
       * }]
       * */
      let bbox = []
      try {
        bbox = Leaflet.geoJSON(this.item).getBounds().toBBoxString()
      } catch (error) {}

      this.sources = this.item.available_at
      this.preview = this.item.preview


      const collectionData = this.getEntity(`${this.endpoint}/collections/${this.collectionID}`)
      const assets = Object.entries(this.item?.assets?.paths || []).map(([k, value]) => {
        const collectionSource = collectionData.sources.find((s) => s.id === this.sourceID)
        const sourceAssets = collectionSource.assets
        const sourceOptions = collectionSource.options
        const assetInfo = sourceAssets[k]
        const isData = assetInfo.roles.includes('data')
        const isFileApi = collectionSource.type == 'file-api'
        const isCog = ['image/tiff', 'application=geotiff', 'profile=cloud-optimized'].every((t) => assetInfo.type.includes(t))

        let href = value || ''
        if (this.item.assets.base_uri) {
          href = this.item.assets.base_uri + '/' + href
        }
        if (sourceOptions && sourceOptions.public) {
          href = href.replace('gs://', 'https://storage.googleapis.com/')
        }
        href = href.replace('//', '/').replace(':/', '://')
        return {
          ...assetInfo,
          key: k,
          href,
          label: assetInfo.title,
          roleNames: assetInfo.roles.join(', '),
          ismm: isCog && isData && isFileApi,
          bbox: bbox
        }
      })

      return assets.sort((a, b) => a.title.localeCompare(b.title))
    },
    _entity() {
      let object = this.getEntity(this.url)
      if (object instanceof Error) {
        return object
      } else if (object.type === 'Feature') {
        this.owned_by = object.owned_by
        this.shared_with = object.shared_with
        this.labels = object.labels

        let cloned = JSON.parse(JSON.stringify(object)) // Clone to avoid changing the vuex store, remove once migration is done directly in vuex
        if (Object.entries(cloned.assets).length === 0) {
          cloned.assets = { paths: {} } // assets.paths must exits
        }
        let mig = Migrate.item(cloned)
        return mig
      }

      let cloned = JSON.parse(JSON.stringify(object)) // Clone to avoid changing the vuex store, remove once migration is done directly in vuex
      return Migrate.item(cloned)
    },
    _collectionLinks() {
      return this.links.filter((x) => x.rel === 'collection')
    },
    _description() {
      return this.properties.description
    },
    keywords() {
      if (this.collection && Array.isArray(this.collection.keywords)) {
        return this.collection.keywords
      } else {
        return common.computed.keywords.apply(this)
      }
    },
    _license() {
      return (
        this.properties.license ||
        (this.collection && this.collection.license) ||
        (this.rootCatalog && this.rootCatalog.license)
      )
    },
    providers() {
      return (
        this.properties.providers ||
        (this.collection && this.collection.providers) ||
        common.computed.providers.apply(this)
      )
    },
    _temporalCoverage() {
      if (this.properties.start_datetime != null) {
        return [this.properties.start_datetime, this.properties.end_datetime].map((x) => x || '..').join('/')
      }
      return this.properties.datetime
    },
    _title() {
      return this.properties.title
    },
    attribution() {
      if (this.license != null || this.licensor != null) {
        return `Imagery ${this.license || ''} ${this.licensor || ''}`
      }

      return null
    },
    bands() {
      // ToDo: Merge all bands from assets
      return (
        this.properties['eo:bands'] ||
        (this.collection && this.collection.properties && this.collection.properties['eo:bands']) ||
        []
      )
    },
    available_at() {
      let available_at = this.assets.find((x) => x.key === 'available_at')
      if (!available_at) {
        available_at = []
      }
      if (available_at) {
        return available_at
      }
      return null
    },
    collection() {
      if (this.collectionLink != null) {
        this.load(this.collectionLink.href)

        return this.getEntity(this.collectionLink.href)
      }

      return null
    },
    collectionLink() {
      return this._collectionLinks.pop()
    },
    entity() {
      return this.item
    },
    features() {
      return this.assets
        .filter((x) => FEATURES_TYPES.includes(x.type))
        .map((features) => ({
          ...features,
          title: `Features: ${features.title}`
        }))
    },
    item() {
      return this._entity
    },
    jsonLD() {
      const dataset = {
        '@context': 'https://schema.org/',
        '@type': 'Dataset',
        // required
        name: this.title,
        description: this.description || `${this.title} STAC Item`,
        // recommended
        citation: this.properties['sci:citation'],
        identifier: this.properties['sci:doi'] || this.item.id,
        keywords: this.keywords,
        license: this.licenseUrl,
        isBasedOn: this.url,
        url: this.path,
        workExample: (this.properties['sci:publications'] || []).map((p) => ({
          identifier: p.doi,
          citation: p.citation
        })),
        includedInDataCatalog: [this.collectionLink, this.parentLink]
          .filter((x) => !!x)
          .map((l) => ({
            isBasedOn: l.href,
            url: l.slug
          })),
        spatialCoverage: {
          '@type': 'Place',
          geo: {
            '@type': 'GeoShape',
            box: (this.item.bbox || []).join(' ')
          }
        },
        temporalCoverage: this._temporalCoverage,
        distribution: this.assets.map((a) => ({
          contentUrl: a.href,
          fileFormat: a.type,
          name: a.title
        })),
        image: this.thumbnail,
        available_at: this.available_at
      }

      return dataset
    },
    licensor() {
      return this.providers
        .filter((x) => (x.roles || []).includes('licensor'))
        .map((x) => {
          if (x.url != null) {
            return `<a href=${x.url}>${x.name}</a>`
          }

          return x.name
        })
        .pop()
    },
    parentLink() {
      return this.links.filter((x) => x.rel === 'parent').pop()
    },
    properties() {
      return this.entity.properties || {}
    },
    sourcesDropdown() {
      const dropdown = this.sources?.slice().sort((a, b) => a.localeCompare(b))
      return dropdown
    }
  },
  watch: {
    ...common.watch
  },
  methods: {
    ...common.methods,
    ...mapActions(['load']),
    initialize() {
      let wkt = new wicket.Wkt()
      if (this.item.geometry) {
        this.geometry = wkt.read(JSON.stringify(this.item.geometry)).write()
      }
    },
    selectTab(source) {
      if (this.sourceID === source) {
        return
      }
      router.push({
        name: 'sourceItem',
        params: {
          collectionID: this.collectionID,
          sourceID: source,
          itemID: this.itemID
        }
      })
    },
    activateTab() {
      this.tabsChanged = true
    },
    async isDisplayable(image_url) {
      if (image_url.includes('proxima')) return true
      try {
        const rsp = await fetch(image_url, { method: 'GET' })
        return rsp.ok
      } catch (e) {
        console.warn(e)
        return false
      }
    },
    async errorImg(event) {
      event.target.style.display = 'none';
      let tiff
      try {
        tiff = await GeoTIFF.fromUrl(event.srcElement.src);
      } catch (e) {
        console.warn("loading failed", event.srcElement.src, e)
        return
      }
      const image = await tiff.getImage();
      const [r,g,b] = await image.readRGB({interleave: false});

      // size = rows * columns * channels
      const w = image.getWidth()
      const h = image.getHeight()
      const data = new Uint8Array(w * h * 3);
      for (let i = 0; i < r.length; i++) {
        data[i*3] = r[i];
        data[i*3 + 1] = g[i];
        data[i*3 + 2] = b[i];
      }

      const x = new IJS.Image(w, h, data, { kind: 'RGB' });

      event.srcElement.src= x.toDataURL();
      
      event.target.style.display = 'block';
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="./base.css"></style>

<style scoped>
#thumbnail img {
  max-width: 100%;
  max-height: 250px;
}
#thumbnail {
  display: flex;
  justify-content: center;
}

.nav-item > .dropdown .btn {
  border: 1px solid transparent;
  padding-top: calc(0.375em + 3px);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.active .dropdown .btn {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  margin-bottom: -16px;
}

.dropdown .btn:focus,
.dropdown .btn:active {
  outline: none !important;
  box-shadow: none;
}
</style>
<style scoped lang="css">
.leaflet-container {
  background-color: #262626;
}

#locator-map {
  height: 200px;
  width: 100%;
  margin-bottom: 10px;
}

#map-container {
  height: 500px;
}

#map {
  height: 100%;
  width: 100%;
}

#header_logo {
  max-width: 100px;
}

.multiselect {
  margin-top: 5px;
}
</style>
